@charset "utf-8";
body{
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	width: 100%;
	min-height: 100%;
	font-family: sans-serif;
	background-color: #ffffff;
}

/*-----------------------------------*/
/*-----------------------------------*/
/*-----------------------------------*/
.SuperSegmentContainer{
	width: 80%;
}
#GeneralBody #CategoriesContainer{
	margin: auto;
	padding: 0;
	display: block;
}
#GeneralBody #CategoriesContainer #CategoriesTitle{
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	color: #153768;
}
#GeneralBody #CategoriesContainer #CategoriesSplitLine{
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
}
#GeneralBody #CategoriesContainer #CategoriesList{
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	list-style: none;
	text-align: center;
}
#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem{
	-webkit-box-shadow: 0px 0px 22px -11px rgba(204,204,204,1);
	-moz-box-shadow: 0px 0px 22px -11px rgba(204,204,204,1);
	box-shadow: 0px 0px 22px -11px rgba(204,204,204,1);
	border-radius: 25px;
	cursor: pointer;
	background-color: #ffffff;
}
#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem:hover .CategoriesListItemLink .CategoriesListItemImage,
#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem:focus .CategoriesListItemLink .CategoriesListItemImage{
	transform: scale(1.1);
}
#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem .CategoriesListItemLink{
	margin: 0;
	padding: 0;
	display: block;
	text-decoration: none;
	position: relative;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	transform: translate(-50%, -50%);
}
#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem .CategoriesListItemLink .CategoriesListItemImage{
	margin: auto;
	padding: 0;
	display: block;
	transition: .3s;
}
#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem .CategoriesListItemLink .CategoriesListItemName{
	margin: 0;
	display: block;
	width: 100%;
	text-align: center;
	color: #153768;
}
/*----------------------------------------------------*/
/*----------------------------------------------------*/
/*----------------------------------------------------*/
#GeneralBody #DiscountMessageContainer{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    background: url('/images/discount_index_background.webp') rgba(5, 216, 221, 1) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-blend-mode: multiply;
    text-align: center;
}
/*#IndexContainer #DiscountMessageContainer #DiscountMessageTextContainer,
#IndexContainer #DiscountMessageContainer #DiscountMessageInputContainer{

}*/
#GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageTitle,
#GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageSubTitle,
#GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageText{
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    color: #FFFFFF;
}
#GeneralBody #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageInput{
    background-color: #ffffff;
    color: #666666;
}
#GeneralBody #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageButton{
    background-color: #05D8DD;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    transition: .3s;
}
#GeneralBody #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageButton:hover,
#GeneralBody #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageButton:focus{
    transform: scale(1.1);
}
/*-----------------------------------*/
/*-----------------------------------*/
/*-----------------------------------*/
#GeneralFeed{
	text-align: right;
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	margin-top: 2%;
	margin-bottom: 2%;
	text-align: right;
}
#GeneralFeed #GeneralText{
	padding-right: 1%;
	color: #A9A9A9;
}
#GeneralFeed #GeneralText a{
	text-decoration: none;
	color: #505050;
}
#GeneralFeed #GeneralText a p, #GeneralFeed #GeneralText a img{
	margin: 0;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
}
#GeneralFeed #GeneralText a img{
	width: 40px;
}
/*-----------------------------------*/
/*-----------------------------------*/
/*-----------------------------------*/
#PrincipalFeed{
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	background-image: url("/images/feet_background.webp");
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	
}
#PrincipalFeed #PrinciaplCenteredFeed{
	display: block;
	width: 100%;
}
#PrincipalFeed #PrinciaplCenteredFeed .PrincipalFeedSubContainer .PrincipalFeedSubTitleContainer{
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
}
#PrincipalFeed #PrinciaplCenteredFeed .PrincipalFeedSubContainer .PrincipalFeedSubTitleContainer .PrincipalFeedSubContainerTitle{
	color: #ffffff;
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	text-align: left;
}
#PrincipalFeed #PrinciaplCenteredFeed .PrincipalFeedSubContainer .PrincipalFeedSubTitleContainer .PirncipalFeedBorderLineImage{
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
}
/*-------------------*/
/*-------------------*/
/*-------------------*/
#AskMeContainer{
	margin: 0;
	padding: 0;
	display: block;
	position: fixed;
	bottom: 0%;
	right: 1%;
	z-index: 100%;
	z-index: 100;
}
#AskMeContainer #AskMeAlwaysShow{
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	text-align: right;
	cursor: pointer;
}
#AskMeContainer #AskMeAlwaysShow:hover #AskMeBotImage,
#AskMeContainer #AskMeAlwaysShow:focus #AskMeBotImage{
	transform: scale(1.1);
}
#AskMeContainer #AskMeAlwaysShow #AskMeInitialQuestion,
#AskMeContainer #AskMeAlwaysShow #AskMeBotImage{
	transition: .3s;
	margin: 0;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
}
#AskMeContainer #AskMeAlwaysShow #AskMeInitialQuestion{
	background-color: #153768;
	color: #ffffff;
	padding: 7px 25px 7px 15px;
	border-radius: 10px 10px 0px 0px;
	-moz-border-radius: 10px 10px 0px 0px;
	-webkit-border-radius: 10px 10px 0px 0px;
	border: 0px solid #000000;
	font-size: "SecundaryFont";
}
#AskMeContainer #AskMeAlwaysShow #AskMeBotImage{

}
#AskMeContainer #AskMeChatContainer{
	margin: 0;
	padding: 0;
	display: none;
}
#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer{
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
	background-color: #153768;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}
#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderImage,
#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderCloseButton{
	margin: 0;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
}
#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderImage{

}
#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderCloseButton{
	background-color: transparent;
	color: #ffffff;
	cursor: pointer;
	transition: .3s;
	position: absolute;
	right: 3%;
	border: none;
}
#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderCloseButton:hover,
#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderCloseButton:focus{
	transform: scale(1.1);
}
#AskMeContainer #AskMeChatContainer #AskMeChatTextContainer{
	margin: 0;
	padding: 0;
	display: block;
	background-color: #FFFFFF;
	font-size: "SecundaryFont";
}
#AskMeContainer #AskMeChatContainer #AskMeChatTextContainer .AskMeChatText{
	margin: 0;
	padding: 0;
	display: none;
	text-align: left;
	color: #444444;
	background-color: #E2E2E2;
	border-radius: 7px;
	font-size: "SecundaryFont";
}
#AskMeContainer #AskMeChatContainer #AskMeTexteable{
	margin: 0;
	padding: 0;
	display: block;
	border: none;
	border-top: 1px solid #cccccc;
	background-color: #ffffff;
	color: #444444;
	font-family: sans-serif;
}
/*-------------------*/
/*-------------------*/
/*-------------------*/
@media screen and (min-width: 701px) {
	.SuperSegmentContainer{
		margin: auto;
		padding: 0;
		width: 73%;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#GeneralBody{
		min-height: 100vh;
		background-color: rgba(255,255,255,1);
		-webkit-overflow-scrolling: touch;
	}
	#GeneralBody #CategoriesContainer{
		margin: 4vh auto;
	}
	#GeneralBody #CategoriesContainer #CategoriesTitle{
		font-size: 1.7em;
		margin-top: 3vh;
	}
	#GeneralBody #CategoriesContainer #CategoriesSplitLine{
		margin-top: 1vh;
		margin-top: 3vh;
	}
	#GeneralBody #CategoriesContainer #CategoriesList{
		padding: 3vh 0 0 0;
	}
	#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem{
		display: inline-block;
		vertical-align: middle;
		margin: 1vh 1% 1vh 0;
		padding: 1%;
		width: 150px;
		height: 150px;
	}
	/*#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem .CategoriesListItemLink{
	}*/
	#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem .CategoriesListItemLink .CategoriesListItemImage{
		width: auto;
		max-height: 85px;
		transition: .3s;
	}
	#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem .CategoriesListItemLink .CategoriesListItemName{
		padding: 2vh 0 0 0;
		font-size: 1.2em;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#GeneralBody #DiscountMessageContainer{
		padding: 8vh 0;
		margin: 10vh 0;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer,
	 #GeneralBody #DiscountMessageContainer #DiscountMessageInputContainer{
		 margin: 0;
		 padding: 0;
		 display: inline-block;
		 vertical-align: middle;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer{
		 text-align: left;
		 width: 30%;
		 margin-right: 10%;
		 margin-right: 1%;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageTitle{
		 font-size: 1.8em;
		 margin-bottom: 1vh;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageSubTitle{
		 font-size: 1.4em;
		 margin-bottom: 1vh;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageText{
		 font-size: 1.1em;
	 }
	 #IndexContainer #DiscountMessageContainer #DiscountMessageInputContainer{
		 text-align: left;
		 width: 40%;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageInput{
		 padding: 10px 20px;
		 margin: 0;
		 width: 50%;
		 border-radius: 7px 0px 0px 7px;
		 -moz-border-radius: 7px 0px 0px 7px;
		 -webkit-border-radius: 7px 0px 0px 7px;
		 border: 0px solid #000000;
		 font-size: .9em;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageButton{
		 padding: 10px 20px;
		 margin: 0 0 0 -10px;
		 font-size: .9em;
		 border-radius: 0px 7px 7px 0px;
		 -moz-border-radius: 0px 7px 7px 0px;
		 -webkit-border-radius: 0px 7px 7px 0px;
		 border: 0px solid #000000;
	 }
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed{
		padding: 15vh 0 2vh 0;
		background-color: #1E2432;
	}
	/*#PrincipalFeed #PrinciaplCenteredFeed{

	}*/
	#PrincipalFeed #PrinciaplCenteredFeed .PrincipalFeedSubContainer{
		margin: 0 1%;
		padding: 0;
		display: inline-block;
		vertical-align: top;
		width: 16.5%;
		min-height: 25vh;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer{
		text-align: left;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeedBriefImage{
		height: 30px;
	}
	#PrincipalFeed #PrinciaplCenteredFeed .PrincipalFeedSubContainer .PrincipalFeedSubTitleContainer{
		padding: 10px 0;
	}
	/*#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeedBriefImage{

	}*/
	#PrincipalFeed #PrinciaplCenteredFeed .PrincipalFeedSubContainer .PrincipalFeedSubTitleContainer .PrincipalFeedSubContainerTitle{
		padding-bottom: 13px;
	}
	#PrincipalFeed #PrinciaplCenteredFeed .PrincipalFeedSubContainer .PrincipalFeedSubTitleContainer .PrincipalFeedSubContainerTitle{
		font-size: 20px;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_Brief{
		margin: 0;
		padding: 0;
		width: 100%;
		color: #FFFFFF;
		text-align: left;
		font-size: .9em;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_SocialMediaList{
		margin: 3vh 0 0 0;
		padding: 0;
		width: 100%;
		text-align: left;
		list-style: none;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_SocialMediaList .PrincipalFeed_SocialMediaListItem{
		margin: 0 5px;
		padding: 0;
		display: inline-block;
		vertical-align: middle;
		width: 30px;
		transition: .3s;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_SocialMediaList .PrincipalFeed_SocialMediaListItem:hover,
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_SocialMediaList .PrincipalFeed_SocialMediaListItem:focus{
		transform: scale(1.1);
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_SocialMediaList .PrincipalFeed_SocialMediaListItem img{
		margin: 0;
		padding: 0;
		width: 100%;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedCompanyContainer #PrincipalFeed_CompanyList{
		margin: auto;
		padding: 0;
		text-align: left;
		width: 90%;
		list-style-type: square;
		color: #00C7D6;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedCompanyContainer #PrincipalFeed_CompanyList .PrincipalFeed_CompanyListItem{
		margin: 1.5vh 0;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedCompanyContainer #PrincipalFeed_CompanyList .PrincipalFeed_CompanyListItem .PrincipalFeed_SocialMediaListItem_Link{
		margin: 0;
		padding: 0;
		display: block;
		color: #ffffff;
		list-style: none;
		font-size: .9em;
		transition: .3s;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedCompanyContainer #PrincipalFeed_CompanyList .PrincipalFeed_CompanyListItem .PrincipalFeed_SocialMediaListItem_Link:hover,
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedCompanyContainer #PrincipalFeed_CompanyList .PrincipalFeed_CompanyListItem .PrincipalFeed_SocialMediaListItem_Link:focus{
		color: #00C7D6;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedSupportContainer #PrincipalFeed_SupportList{
		margin: auto;
		padding: 0;
		text-align: left;
		width: 90%;
		list-style-type: square;
		color: #00C7D6;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedSupportContainer #PrincipalFeed_SupportList .PrincipalFeed_SupportListItem{
		margin: 1.5vh 0;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedSupportContainer #PrincipalFeed_SupportList .PrincipalFeed_SupportListItem .PrincipalFeed_SupportItem_Link{
		margin: 0;
		padding: 0;
		display: block;
		color: #ffffff;
		list-style: none;
		font-size: .9em;
		transition: .3s;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedSupportContainer #PrincipalFeed_SupportList .PrincipalFeed_SupportListItem .PrincipalFeed_SupportItem_Link:hover,
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedSupportContainer #PrincipalFeed_SupportList .PrincipalFeed_SupportListItem .PrincipalFeed_SupportItem_Link:focus{
		color: #00C7D6;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedContactContainer #PrincipalFeed_ContactList{
		margin: auto;
		padding: 0;
		text-align: left;
		width: 90%;
		list-style-type: square;
		color: #00C7D6;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedContactContainer #PrincipalFeed_ContactList .PrincipalFeed_ContactListItem{
		margin: 1.5vh 0;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedContactContainer #PrincipalFeed_ContactList .PrincipalFeed_ContactListItem .PrincipalFeed_ContactItem_Link{
		margin: 0;
		padding: 0;
		display: block;
		color: #ffffff;
		list-style: none;
		font-size: .9em;
		transition: .3s;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedContactContainer #PrincipalFeed_ContactList .PrincipalFeed_ContactListItem .PrincipalFeed_ContactItem_Link:hover,
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedContactContainer #PrincipalFeed_ContactList .PrincipalFeed_ContactListItem .PrincipalFeed_ContactItem_Link:focus{
		color: #00C7D6;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed #PrincipalFeedFeed{
		margin: 4vh auto 1vh auto;
		padding: 2vh 0;
		display: block;
		border-top: 1px solid #ffffff;
		width: 50%;
	}
	#PrincipalFeed #PrincipalFeedFeed #PrincipalFeedFeedParagraph{
		margin: 0;
		padding: 0;
		display: block;
		width: 100%;
		text-align: center;
		color: #ffffff;
		font-size: .8em;
	}
	/*-------------------*/
	/*-------------------*/
	/*-------------------*/
	#AskMeContainer{
		
	}
	#AskMeContainer #AskMeAlwaysShow{

	}
	#AskMeContainer #AskMeAlwaysShow #AskMeInitialQuestion{
		font-size: .9em;
	}
	#AskMeContainer #AskMeAlwaysShow #AskMeBotImage{
		width: 63px;
		margin-left: -25px;
	}
	#AskMeContainer #AskMeChatContainer{
		width: 300px;
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer{
		padding-top: 10px;
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderImage,
	#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderCloseButton{
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderImage{
		width: 30px;
		padding-left: 10px;
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderCloseButton{
		font-size: 1.3em;
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatTextContainer{
		padding: 15px 0% 150px 4%;
		width: 96%;
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatTextContainer .AskMeChatText{
		font-size: .9em;
		width: 70%;
		padding: 7px 3% 7px 3%;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	#AskMeContainer #AskMeChatContainer #AskMeTexteable{
		padding: 5px 2% 5px 2%;
		width: 96%;
	}
	/*-------------------*/
	/*-------------------*/
	/*-------------------*/
}
@media screen and (max-width: 700px) {
	.SuperSegmentContainer{
		margin: auto;
		padding: 0;
		width: 95%;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#GeneralBody{
		max-height: 100vh;
		background-color: rgba(255,255,255,1);
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
	}
	#GeneralBody #CategoriesContainer{
		margin: 4vh auto;
	}
	#GeneralBody #CategoriesContainer #CategoriesTitle{
		font-size: 1.7em;
		margin-top: 3vh;
	}
	#GeneralBody #CategoriesContainer #CategoriesSplitLine{
		margin-top: 1vh;
		margin-top: 3vh;
	}
	#GeneralBody #CategoriesContainer #CategoriesList{
		padding: 3vh 0 0 0;
		text-align: center;
	}
	#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem{
		display: inline-block;
		vertical-align: middle;
		margin: 1vh auto;
		padding: 1%;
		width: 150px;
		height: 150px;
	}
	/*#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem .CategoriesListItemLink{
	}*/
	#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem .CategoriesListItemLink .CategoriesListItemImage{
		width: 50%;
		transition: .3s;
	}
	#GeneralBody #CategoriesContainer #CategoriesList .CategoriesListItem .CategoriesListItemLink .CategoriesListItemName{
		padding: 2vh 0 0 0;
		font-size: 1.2em;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#GeneralBody #DiscountMessageContainer{
		padding: 8vh 0;
		margin: 10vh 0;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer,
	 #GeneralBody #DiscountMessageContainer #DiscountMessageInputContainer{
		 margin: auto;
		 padding: 0;
		 display: block;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer{
		 text-align: left;
		 width: 90%;
		 margin-bottom: 5vh;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageTitle{
		 font-size: 1.5em;
		 margin-bottom: 1vh;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageSubTitle{
		 font-size: 1.2em;
		 margin-bottom: 1vh;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageTextContainer #DiscountMessageText{
		 font-size: 1em;
	 }
	 #IndexContainer #DiscountMessageContainer #DiscountMessageInputContainer{
		 text-align: left;
		 width: 40%;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageInput{
		 padding: 10px 20px;
		 margin: 0;
		 width: 50%;
		 border-radius: 7px 0px 0px 7px;
		 -moz-border-radius: 7px 0px 0px 7px;
		 -webkit-border-radius: 7px 0px 0px 7px;
		 border: 0px solid #000000;
		 font-size: .9em;
	 }
	 #GeneralBody #DiscountMessageContainer #DiscountMessageInputContainer #DiscountMessageButton{
		 padding: 10px 20px;
		 margin: 0 0 0 -10px;
		 font-size: .9em;
		 border-radius: 0px 7px 7px 0px;
		 -moz-border-radius: 0px 7px 7px 0px;
		 -webkit-border-radius: 0px 7px 7px 0px;
		 border: 0px solid #000000;
	 }
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed{
		padding: 15vh 0 2vh 0;
		background-color: #1E2432;
	}
	/*#PrincipalFeed #PrinciaplCenteredFeed{

	}*/
	#PrincipalFeed #PrinciaplCenteredFeed .PrincipalFeedSubContainer{
		margin: 0 1%;
		padding: 0;
		display: inline-block;
		vertical-align: top;
		width: 45%;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer{
		text-align: left;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeedBriefImage{
		height: 30px;
	}
	#PrincipalFeed #PrinciaplCenteredFeed .PrincipalFeedSubContainer .PrincipalFeedSubTitleContainer{
		padding: 10px 0;
	}
	/*#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeedBriefImage{

	}*/
	#PrincipalFeed #PrinciaplCenteredFeed .PrincipalFeedSubContainer .PrincipalFeedSubTitleContainer .PrincipalFeedSubContainerTitle{
		padding-bottom: 13px;
	}
	#PrincipalFeed #PrinciaplCenteredFeed .PrincipalFeedSubContainer .PrincipalFeedSubTitleContainer .PrincipalFeedSubContainerTitle{
		font-size: 20px;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_Brief{
		margin: 0;
		padding: 0;
		width: 100%;
		color: #FFFFFF;
		text-align: left;
		font-size: .9em;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_SocialMediaList{
		margin: 3vh 0 0 0;
		padding: 0;
		width: 100%;
		text-align: left;
		list-style: none;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_SocialMediaList .PrincipalFeed_SocialMediaListItem{
		margin: 0 5px;
		padding: 0;
		display: inline-block;
		vertical-align: middle;
		width: 30px;
		transition: .3s;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_SocialMediaList .PrincipalFeed_SocialMediaListItem:hover,
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_SocialMediaList .PrincipalFeed_SocialMediaListItem:focus{
		transform: scale(1.1);
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedBriefContainer #PrincipalFeed_SocialMediaList .PrincipalFeed_SocialMediaListItem img{
		margin: 0;
		padding: 0;
		width: 100%;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedCompanyContainer #PrincipalFeed_CompanyList{
		margin: auto;
		padding: 0;
		text-align: left;
		width: 90%;
		list-style-type: square;
		color: #00C7D6;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedCompanyContainer #PrincipalFeed_CompanyList .PrincipalFeed_CompanyListItem{
		margin: 1.5vh 0;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedCompanyContainer #PrincipalFeed_CompanyList .PrincipalFeed_CompanyListItem .PrincipalFeed_SocialMediaListItem_Link{
		margin: 0;
		padding: 0;
		display: block;
		color: #ffffff;
		list-style: none;
		font-size: .9em;
		transition: .3s;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedCompanyContainer #PrincipalFeed_CompanyList .PrincipalFeed_CompanyListItem .PrincipalFeed_SocialMediaListItem_Link:hover,
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedCompanyContainer #PrincipalFeed_CompanyList .PrincipalFeed_CompanyListItem .PrincipalFeed_SocialMediaListItem_Link:focus{
		color: #00C7D6;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedSupportContainer #PrincipalFeed_SupportList{
		margin: auto;
		padding: 0;
		text-align: left;
		width: 90%;
		list-style-type: square;
		color: #00C7D6;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedSupportContainer #PrincipalFeed_SupportList .PrincipalFeed_SupportListItem{
		margin: 1.5vh 0;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedSupportContainer #PrincipalFeed_SupportList .PrincipalFeed_SupportListItem .PrincipalFeed_SupportItem_Link{
		margin: 0;
		padding: 0;
		display: block;
		color: #ffffff;
		list-style: none;
		font-size: .9em;
		transition: .3s;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedSupportContainer #PrincipalFeed_SupportList .PrincipalFeed_SupportListItem .PrincipalFeed_SupportItem_Link:hover,
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedSupportContainer #PrincipalFeed_SupportList .PrincipalFeed_SupportListItem .PrincipalFeed_SupportItem_Link:focus{
		color: #00C7D6;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedContactContainer #PrincipalFeed_ContactList{
		margin: auto;
		padding: 0;
		text-align: left;
		width: 90%;
		list-style-type: square;
		color: #00C7D6;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedContactContainer #PrincipalFeed_ContactList .PrincipalFeed_ContactListItem{
		margin: 1.5vh 0;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedContactContainer #PrincipalFeed_ContactList .PrincipalFeed_ContactListItem .PrincipalFeed_ContactItem_Link{
		margin: 0;
		padding: 0;
		display: block;
		color: #ffffff;
		list-style: none;
		font-size: .9em;
		transition: .3s;
	}
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedContactContainer #PrincipalFeed_ContactList .PrincipalFeed_ContactListItem .PrincipalFeed_ContactItem_Link:hover,
	#PrincipalFeed #PrinciaplCenteredFeed #PrincipalFeedContactContainer #PrincipalFeed_ContactList .PrincipalFeed_ContactListItem .PrincipalFeed_ContactItem_Link:focus{
		color: #00C7D6;
	}
	/*-----------------------------------*/
	/*-----------------------------------*/
	/*-----------------------------------*/
	#PrincipalFeed #PrincipalFeedFeed{
		margin: 4vh auto 1vh auto;
		padding: 2vh 0;
		display: block;
		border-top: 1px solid #ffffff;
		width: 50%;
	}
	#PrincipalFeed #PrincipalFeedFeed #PrincipalFeedFeedParagraph{
		margin: 0;
		padding: 0;
		display: block;
		width: 100%;
		text-align: center;
		color: #ffffff;
		font-size: .8em;
	}
	/*-------------------*/
	/*-------------------*/
	/*-------------------*/
	#AskMeContainer{
		
	}
	#AskMeContainer #AskMeAlwaysShow{

	}
	#AskMeContainer #AskMeAlwaysShow #AskMeInitialQuestion{
		font-size: .9em;
	}
	#AskMeContainer #AskMeAlwaysShow #AskMeBotImage{
		width: 63px;
		margin-left: -25px;
	}
	#AskMeContainer #AskMeChatContainer{
		width: 300px;
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer{
		padding-top: 10px;
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderImage,
	#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderCloseButton{
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderImage{
		width: 30px;
		padding-left: 10px;
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatHeaderContainer #AskMeChatHeaderCloseButton{
		font-size: 1.3em;
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatTextContainer{
		padding: 15px 0% 150px 4%;
		width: 96%;
	}
	#AskMeContainer #AskMeChatContainer #AskMeChatTextContainer .AskMeChatText{
		font-size: .9em;
		width: 70%;
		padding: 7px 3% 7px 3%;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	#AskMeContainer #AskMeChatContainer #AskMeTexteable{
		padding: 5px 2% 5px 2%;
		width: 96%;
	}
	/*-------------------*/
	/*-------------------*/
	/*-------------------*/
}